.about {
  overflow-x: clip;
  position: relative;
 padding: 40px 0;
 background-image: url(../images/about-bcg.svg);
 background-size: 53%;
 background-position: right;
 background-repeat: no-repeat;
  @include media("<=1024px") {
  }
  @include media("<=810px") {
  }
  @include media("<=430px") {
    
  }
  .section__title {
    color: #fff;
  }
}

.about__inner {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  z-index: 2;
color: #fff;
overflow: hidden;
  @include media("<=810px") {
    flex-direction: column;
gap: 30px;
  }
  @include media("<=560px") {
     
  }
}

.about__content {
  max-width: 468px;
  width: 100%;

}

.about__title {
  font-size: 30px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.about__text {
  padding-bottom: 50px;
  font-size: 14px;
  line-height: 1.2;
  color: #979797;
  
  @include media("<=1080px") {
    
  }
  @include media("<=810px") {
   
  }
  @include media("<=430px") {
   
  } 
  p {
    padding-top: 20px;
    @include media("<=810px") {
      
    }
  }
}

.about__image {
  max-width: 361px;
  width: 100%;
  @include media("<=560px") {
    width: 80%;
  }
  img {
    width: 100%;
  }
}






