.features {
    padding: 100px 0;
    background: #F6F6F6;
}

.features__inner {
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
    flex-wrap: wrap;
    gap: 20px;
    @include media("<=840px") {
       gap: 20px;
       flex-wrap: wrap;
       justify-content: space-around;
    }
}

.features__item {
    display: flex;
    flex-direction: column;
    flex: 0 1 48%;
    color: #141414;
background: #fff;
font-size: 14px;
line-height: 1.2;
letter-spacing: 1.4px;
@include media("<=840px") {
    flex: 0 1 48%;
}
@include media("<=530px") {
    flex: 0 1 100%;
}
    
}

.features__image {
    width: 100%;
    img {
        width: 100%;
    }
}

.features__content {
    padding: 32px 20px;
}

.features__title {
    color: #141414;
    margin-bottom: 13px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
}