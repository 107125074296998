.contacts {
    background: #001AFF;
    padding: 80px 0;
}

.contacts__title {
    font-size: 13vw;
        font-weight: 600;
       
        line-height: 1.2;
}

.contacts__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    
}


.contacts__content {
    display: flex;
    gap: 20px;
    justify-content: space-around;
    flex: 0 1 100%;

    @include media("<=810px") {
        flex-direction: column;
        align-items: center;
        row-gap: 30px;
    }
}

.contacts__text {
    max-width: 468px;
    span {
        display: block;
        margin-bottom: 10px;
        font-size: 30px;
        font-weight: 600;
        text-transform: uppercase;
        @include media("<=550px") {
            font-size: 24px;
        }
    }
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
    width: 100%;
@include media("<=810px") {
   
}
}

.form__input {
    width: 100%;
    input {
        font-family: "Montserrat", sans-serif;
        width: 100%;
        font-size: 14px;
        font-weight: 700;
        color: rgba(0, 0, 0, 0.60);
        border-radius: 60px;
border: 1px solid #000;
        padding: 17px 20px;
    @include media("<=430px") {
        
    }
    }
    textarea {
        font-family: "Montserrat", sans-serif;
        width: 100%;
        font-size: 14px;
        font-weight: 700;
        color: rgba(0, 0, 0, 0.60);
        border-radius: 60px;
border: 1px solid #000;
        padding: 17px 20px;
        resize: none;
    @include media("<=430px") {
        
    }
    }
}

.form__button {

    font-family: "Montserrat", sans-serif;
    border-radius: 60px;
background: #000;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #fff;
        padding: 16px;
        width: 100%;
        border: none;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        cursor: pointer;
        transition: all 0.3s linear;
        &:hover {
            background-color: #000;
        }
}

