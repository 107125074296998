.hero {
  position: relative;
  padding: 120px 0 0;

  @include media("<=1080px") {

  }

  @include media("<=810px") {
    padding-top: 80px;
  }

  @include media("<=430px") {
    
    
  }
}

.hero__inner {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: flex-end;
  column-gap: 30px;
  padding-bottom: 60px;
  @include media("<=1280px") {

    margin: 0 auto;
  }

  @include media("<=1080px") {}

  @include media("<=810px") {
    position: relative;
    flex-direction: column;
    align-items: center;
    padding-bottom: 0;
  }

  @include media("<=430px") {}
}


.hero__title {
  font-size: 100px;
  font-weight: 600;
  width: 100%;
  position: relative;
  z-index: 3;
  text-transform: uppercase;
max-width: 675px;
  @include media("<=1280px") {
    
    
  }

  @include media("<=1080px") {
   

  }

  @include media("<=810px") {
    margin-bottom: 40px;
 
  }

  @include media("<=700px") {
   font-size: 72px;

  }

  @include media("<=550px") {
    font-size: 56px;
  }
  @include media("<=430px") {
    font-size: 36px;
    max-width: 248px;
  }
}


.arrow-b {
  max-width: 122px;
  width: 100%;
  position: relative;
  z-index: 3;
  transition: all 0.3s ease-in-out;
  @include media("<=810px") {
    margin-bottom: -60px;
  }
  img {
    width: 100%;
    display: block;

  }
  &:hover {
    transform: rotate(90deg);
  }
}

.button {
  position: relative;
  display: block;
  max-width: 71px;
  aspect-ratio: 1 / 1;
  width: 100%;
  border-radius: 50%;
  transition: all 0.3s linear;
 z-index: 3;

  @include media("<=430px") {

  }
 &-hero {
  max-width: 58px;
  @include media("<=810px") {
    display: none;
  }
 }
 &-about {
  position: absolute;
  right: 37px;
  bottom: 0;
 }
  &:hover {
    transform: rotate(128deg);
    transform-origin: center center;
  }
}

.hero__image {
  flex: 0 1 100%;
  width: 100%;
  position: relative;
  
  @include media("<=810px") {
   
  }

  @include media("<=430px") {
    
  }

  img {
    display: block;
    width: 100%;
    @include media("<=810px") {
      width: 100%;
    }
  }
}