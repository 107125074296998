.gallery {
    background: #001AFF;
    padding: 80px 0;
}

.gallery__inner {
    display: flex;
  align-items: center;
  justify-content: center;
  @include media("<=650px") {
     flex-wrap: wrap;
     padding: 0 15px;
  }
}

.gallery__item {
    flex: 0 1 25%;
    @include media("<=650px") {
        flex: 0 1 50%;
    }
    img {
        width: 100%;
        display: block;
    }
}