body {
  margin: 0;
  font-family: "Archivo", sans-serif;
  color: #fff;
  background-color: #030406;
  position: relative;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
}

.container {
  width: 100%;
  max-width: 1230px;
  padding: 0 15px;
  margin: 0 auto;
}

.marquee {
  padding: 55px 0;
  background: #001AFF;
  overflow: hidden;
}

.marquee__inner {
  font-size: 100px;
  display: flex;
  gap: 30px;
  white-space: nowrap;
  will-change: transform;
  animation: marquee 32s linear infinite;
  div {
  border-radius: 50px;
background: #000;
width: 100%;
max-width: 14px;
height: 14px;
display: block;
  }
 
    
  
}

@keyframes marquee {
  from { transform: translateX(0); }
  to { transform: translateX(-50%); }
}

.section__title {
  color: #001AFF;
  font-size: 60px;
  font-weight: 600;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  column-gap: 10px;
  @include media("<=1080px") {
    font-size: 54px;
  }
  @include media("<=810px") {
   
  }
  @include media("<=450px") {
    font-size: 42px;
  }
  @include media("<=350px") {
    
  }
  
}

.arrow-w {
  @include media("<=500px") {
    display: none;
  }
}

.page__hero {
  padding: 40px 0;
}

.page__title {
  text-align: center;
  font-size: 64px;
  font-weight: 700;
  margin-bottom: 40px;
  @include media("<=810px") {
    font-size: 42px;
  }
}

.page__content {
  padding: 70px 0 100px;
  font-size: 14px;
  line-height: 1.2;
  background-color: #fff;
  color: #000;
  strong {
    font-weight: 600;
    display: block;
    padding-top: 18px;
  }
}



.page__text {
  line-height: 1.2;
  font-size: 18px;
  font-weight: 500;
  p {
    padding-top: 18px;
  }
}


