.info {
    padding: 100px 0 100px;
    background: #F6F6F6;
    @include media("<=810px") {
      
    }
    .section__title {
        
    }
}

.info__inner {
    padding-top: 40px;
    display: flex;
    
    justify-content: space-between;
    gap: 20px;
    @include media("<=810px") {
        flex-direction: column;
        align-items: center;
        gap: 30px;
        
    }
}

.info__item {
    background-color: #fff;
    flex: 0 1 32%;
    display: flex;
  justify-content: space-between;
  flex-direction: column;
  @include media("<=810px") {
    flex: 0 1 100%;
    width: 100%;
    max-width: 500px;
  }
}

.info__title {
        font-size: 30px;
        font-weight: 600;
        background-color: #001AFF;
        width: 100%;
        line-height: 1.2;
        padding: 16px;
        text-transform: uppercase;


        @include media("<=810px") {
            
        }
        @include media("<=430px") {
           
        }
        p {
            
        }
}

.info__list {
    color: #585858;
    padding: 38px 20px 20px 40px;
    list-style-type: disc;
    line-height: 1.3;
}

.info__text {
    margin: 60px auto 40px;
    max-width: 600px;
    width: 100%;
    color: #585858;
    text-align: center;
        font-size: 16px;
       line-height: 1.2;
        @include media("<=810px") {
   
        }
            
        

}

.info__button {
    background-color: #001AFF;
    margin: 0 auto;
    border-radius: 50%;
    display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 122px;
  width: 100%;
  aspect-ratio:  1 / 1;
  color: #fff;
  text-decoration: none;
}

